<template>
    <div>
      <el-dialog
          v-loading="listLoading"
          :visible.sync="isActiveSelectTable"
          width="1200px"
          inline
          :close-on-click-modal="false"
          :show-close="false"
      >
        <div slot="title">
          <span style="border-bottom:2px solid #1386ce">导出表格</span>
        </div>
        <el-table
            :data="tableHead"
            size="mini"
            v-loading="listLoading"
            :header-cell-style="{ 'text-align': 'center' }"
            border
            height="600"
            @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center">
          </el-table-column>
          <el-table-column type="index" align="center" />
          <el-table-column
              prop="column_label_user_definition"
              label="导出表头"
              width="auto"
              align="center"
          >
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleCancel" size="mini">取 消</el-button>
          <el-button type="primary" @click="_ok" size="mini">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  <script>
  import { getAdminFieldIndex } from "@/api/drugs2.0";
  export default {
    props: {
      views_type: {
        type: String,
        default: "",
      },
      isActiveSelectTable: {
        type: Boolean,
        default: false,
      },
    },
    async created() {
      await this._getAdminFieldIndex();
    },
    data() {
      return {
        listLoading: false,
        tableHead: [],
        multipleSelection: [],
      };
    },
    methods: {
      handleSelectionChange(val) {
        console.log(val);
        this.multipleSelection = val;
      },
      handleCancel(done) {
        console.log(111, done);
        this.$emit("update:isActiveSelectTable", false);
      },
      async _getAdminFieldIndex() {
        try {
          this.listLoading = true;
          var params = {
            type: this.views_type,
          };
          const res = await getAdminFieldIndex(params);
          let tableHead = [];
          res.data.forEach((item) => {
            if (item.visible === true) {
              tableHead.push(item);
            }
          });
          this.tableHead = tableHead;
        } catch (err) {
          //在此处理错误
        } finally {
          this.listLoading = false;
        }
      },
      _ok() {
        if (this.multipleSelection.length === 0) {
          this.$message({
            type: "error",
            message: "请选择导出表头",
          });
          return;
        }
        let fields = [];
        this.multipleSelection.forEach((item) => {
          fields.push({
            en: item.column_prop,
            zh: item.column_label_user_definition,
          });
        });
        this.$emit("exportSelectTable", fields)
      },
    },
  };
  </script>
  